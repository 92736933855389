import { atom } from "recoil"
import { UserOut } from "../generated/fetchclient"

export const JWT = atom<string | undefined>({
  key: "JWT",
  default: undefined,
})

export const isHuxleyUser = atom<boolean | undefined>({
  key: "isHuxleyUser",
  default: undefined,
})

export const currentUser = atom<UserOut | undefined>({
  key: "currentUser",
  default: undefined,
})
