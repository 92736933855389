/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Schema for returning a Label to the frontend
 * @export
 * @interface LabelOut
 */
export interface LabelOut {
    /**
     * 
     * @type {string}
     * @memberof LabelOut
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof LabelOut
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof LabelOut
     */
    color: string;
}

/**
 * Check if a given object implements the LabelOut interface.
 */
export function instanceOfLabelOut(value: object): value is LabelOut {
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('color' in value) || value['color'] === undefined) return false;
    return true;
}

export function LabelOutFromJSON(json: any): LabelOut {
    return LabelOutFromJSONTyped(json, false);
}

export function LabelOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabelOut {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'value': json['value'],
        'color': json['color'],
    };
}

export function LabelOutToJSON(json: any): LabelOut {
    return LabelOutToJSONTyped(json, false);
}

export function LabelOutToJSONTyped(value?: LabelOut | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'uuid': value['uuid'],
        'value': value['value'],
        'color': value['color'],
    };
}

