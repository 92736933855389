import { useEffect } from "react"
import { useIdleTimer } from "react-idle-timer"
import { Outlet, useLocation } from "react-router-dom"
import { useRecoilValue } from "recoil"
import LoadingIndicator from "../components/loading/LoadingIndicator"
import { usePortalApi } from "../connections"
import useUserAuthenticator from "../hooks/useUserAuthenticator"
import { currentUser, isHuxleyUser, JWT } from "../state/auth.state"
import { useAuthenticator } from "@aws-amplify/ui-react"

const RequireAuth = () => {
  const isAdmin = useRecoilValue(isHuxleyUser)
  const { user } = useAuthenticator()
  const location = useLocation()
  const currentUserData = useRecoilValue(currentUser)
  const { userSignOut, userAuthentication, getValidToken } =
    useUserAuthenticator()
  const api = usePortalApi()
  const jwt = useRecoilValue(JWT)

  const onIdle = () => {
    if (isAdmin) {
      userSignOut()
    }
  }

  useIdleTimer({
    onIdle,
    timeout: 15 * 60 * 1000, // 15 minutes
    throttle: 1000,
  })

  useEffect(() => {
    const updateJWT = async () => {
      const token = await getValidToken()
      if (!token) {
        userSignOut()
      }
    }

    updateJWT()
  }, [user, location.pathname])

  useEffect(() => {
    if (!api || !jwt || !user) return
    userAuthentication()
  }, [api, jwt, user])

  if (user && (!currentUserData || !jwt || !api)) {
    return <LoadingIndicator header="" content=" " />
  }

  return <Outlet />
}

export default RequireAuth
