export const userRoleOptions = [
  { label: "Huxley Admin", value: "HuxleyAdmin" },
  { label: "Huxley Support", value: "HuxleySupport" },
  { label: "Organization Admin", value: "OrgAdmin" },
  { label: "Medical Technician", value: "MedTech" },
  { label: "Physician", value: "Physician" },
]

export const canViewUserPageRoles = ["HuxleyAdmin", "HuxleySupport", "OrgAdmin"]
export const canCreateInterpretationGroups = [
  "MedTech",
  "Physician",
  "OrgAdmin",
]
