export enum UserRole {
  HuxleyAdmin = "Huxley Admin",
  HuxleySupport = "Huxley Support",
  OrgAdmin = "Organization Admin",
  MedTech = "Medical Technician",
  Physician = "Physician",
}

export enum UserRoleValue {
  HUXLEYADMIN = "HuxleyAdmin",
  HuxleySupport = "HuxleySupport",
  ORGADMIN = "OrgAdmin",
  MEDTECH = "MedTech",
  PHYSICIAN = "Physician",
}
