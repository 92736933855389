import { Amplify } from "aws-amplify"
import { cognitoUserPoolId, cognitoClientId } from "../const/awscognito.const"

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: cognitoUserPoolId as string, // OPTIONAL - Amazon Cognito User Pool ID
      userPoolClientId: cognitoClientId as string, // OPTIONAL - Amazon Cognito Web Client ID
      passwordFormat: {
        minLength: 8,
        requireLowercase: true,
        requireUppercase: true,
        requireNumbers: true,
        requireSpecialCharacters: true,
      },
    },
  },
})
