import "@aws-amplify/ui-react/styles.css"
import { useEffect } from "react"
import { useAuthenticator } from "@aws-amplify/ui-react"
import AuthLayout from "./AuthContainer"
import { Box, Typography } from "@mui/joy"
import useUserAuthenticator from "../../hooks/useUserAuthenticator"
import AuthStyle from "./AuthStyle"
import { useNavigate } from "react-router-dom"

export function CognitoLogin() {
  const { user } = useAuthenticator()
  const navigate = useNavigate()
  const { setJWTToken } = useUserAuthenticator()

  useEffect(() => {
    if (!user) return

    const setJwt = async () => {
      if (await setJWTToken()) {
        navigate("/")
      }
    }
    setJwt()
  }, [user])

  return (
    <AuthLayout>
      <AuthStyle />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography fontSize={7}>
          Version: {process.env.REACT_APP_GIT_TAG}
        </Typography>
      </Box>
    </AuthLayout>
  )
}

export default CognitoLogin
