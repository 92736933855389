/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SleepStudyOutBase
 */
export interface SleepStudyOutBase {
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOutBase
     */
    uuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof SleepStudyOutBase
     */
    deletedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof SleepStudyOutBase
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOutBase
     */
    assignedDevice: string;
    /**
     * 
     * @type {Date}
     * @memberof SleepStudyOutBase
     */
    start?: Date;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOutBase
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOutBase
     */
    autoscoreJsonUri?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOutBase
     */
    uploadId?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOutBase
     */
    interpretation?: string;
}

/**
 * Check if a given object implements the SleepStudyOutBase interface.
 */
export function instanceOfSleepStudyOutBase(value: object): value is SleepStudyOutBase {
    if (!('assignedDevice' in value) || value['assignedDevice'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function SleepStudyOutBaseFromJSON(json: any): SleepStudyOutBase {
    return SleepStudyOutBaseFromJSONTyped(json, false);
}

export function SleepStudyOutBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SleepStudyOutBase {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'deletedAt': json['deleted_at'] == null ? undefined : (new Date(json['deleted_at'])),
        'id': json['id'] == null ? undefined : json['id'],
        'assignedDevice': json['assigned_device'],
        'start': json['start'] == null ? undefined : (new Date(json['start'])),
        'status': json['status'],
        'autoscoreJsonUri': json['autoscore_json_uri'] == null ? undefined : json['autoscore_json_uri'],
        'uploadId': json['upload_id'] == null ? undefined : json['upload_id'],
        'interpretation': json['interpretation'] == null ? undefined : json['interpretation'],
    };
}

export function SleepStudyOutBaseToJSON(json: any): SleepStudyOutBase {
    return SleepStudyOutBaseToJSONTyped(json, false);
}

export function SleepStudyOutBaseToJSONTyped(value?: SleepStudyOutBase | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'uuid': value['uuid'],
        'deleted_at': value['deletedAt'] == null ? undefined : ((value['deletedAt']).toISOString()),
        'id': value['id'],
        'assigned_device': value['assignedDevice'],
        'start': value['start'] == null ? undefined : ((value['start']).toISOString()),
        'status': value['status'],
        'autoscore_json_uri': value['autoscoreJsonUri'],
        'upload_id': value['uploadId'],
        'interpretation': value['interpretation'],
    };
}

