import { Box } from "@mui/joy"
import Header from "../../../components/Header"
import Sidebar from "../../../components/sidebar/Sidebar"
import { Outlet } from "react-router-dom"
import { useEffect, useRef } from "react"
import { useSetRecoilState } from "recoil"
import { chartWidth, mainHeight } from "../../../state/graph.state"
import { isInvalidNumber } from "../../../utils/utils"
import UploadsStatusBar from "../../../components/uploadsStatusBar/UploadsStatusBar"

interface AuthenticatedLayoutParams {
  children?: React.ReactNode
  header?: React.ReactNode
}

const AuthenticatedLayout = ({ children }: AuthenticatedLayoutParams) => {
  const mainRef = useRef<HTMLDivElement>(null)
  const sidebarRef = useRef<HTMLDivElement>(null)
  const setChartWidth = useSetRecoilState(chartWidth)
  const setMainHeight = useSetRecoilState(mainHeight)

  const updateDimensions = () => {
    if (
      mainRef.current &&
      sidebarRef.current &&
      !isInvalidNumber(mainRef.current.offsetHeight)
    ) {
      const mainDimesnionsComputedStyle = window.getComputedStyle(
        mainRef.current
      )
      const sidebarDimesnionsComputedStyle = window.getComputedStyle(
        sidebarRef.current
      )
      const sidebarPaddingRight = parseFloat(
        sidebarDimesnionsComputedStyle.paddingRight
      )
      const mainPaddingRight = parseFloat(
        mainDimesnionsComputedStyle.paddingRight
      )
      const mainPaddingLeft = parseFloat(
        mainDimesnionsComputedStyle.paddingLeft
      )
      const mainMarginLeft = parseFloat(mainDimesnionsComputedStyle.marginLeft)
      const mainMarginRight = parseFloat(
        mainDimesnionsComputedStyle.marginRight
      )
      const mainPaddingTop = parseFloat(mainDimesnionsComputedStyle.paddingTop)
      const mainPaddingBottom = parseFloat(
        mainDimesnionsComputedStyle.paddingBottom
      )
      const mainMarginTop = parseFloat(mainDimesnionsComputedStyle.marginTop)
      const mainMarginBottom = parseFloat(
        mainDimesnionsComputedStyle.marginBottom
      )
      const chartWidthTotal =
        window.innerWidth -
        mainPaddingLeft -
        mainPaddingRight -
        mainMarginLeft -
        mainMarginRight -
        sidebarPaddingRight

      const mainHeight =
        window.innerHeight -
        mainPaddingTop -
        mainPaddingBottom -
        mainMarginTop -
        mainMarginBottom
      setMainHeight(mainHeight)
      setChartWidth(chartWidthTotal)
    }
  }

  useEffect(() => {
    const mainDimensions = new ResizeObserver(updateDimensions)
    const sidebarDimensions = new ResizeObserver(updateDimensions)

    if (mainRef.current && sidebarRef.current) {
      mainDimensions.observe(mainRef.current)
      sidebarDimensions.observe(sidebarRef.current)
    }

    window.addEventListener("resize", updateDimensions)

    return () => {
      window.removeEventListener("resize", updateDimensions)
      mainDimensions.disconnect()
      sidebarDimensions.disconnect()
    }
  }, [])

  useEffect(() => {
    if (mainRef.current && sidebarRef.current) {
      updateDimensions()
    }
  }, [mainRef, sidebarRef])

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100dvh",
        height: "100vh",
        boxSizing: "border-box",
      }}
    >
      <Header />
      <Sidebar ref={sidebarRef} />
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: {
            xs: 2,
            md: 2,
          },
          pt: {
            xs: "calc(12px + var(--Header-height))",
            sm: "calc(12px + var(--Header-height))",
            md: 1,
          },
          pb: {
            xs: 2,
            sm: 2,
            md: 3,
          },
          marginLeft: {
            xs: 0,
            md: "80px",
          },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minWidth: 0,
          height: "100%",
          boxSizing: "border-box",
          gap: 1,
        }}
        ref={mainRef}
      >
        <Outlet />
      </Box>
      <UploadsStatusBar />
    </Box>
  )
}

export default AuthenticatedLayout
