import { ReactElement, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { styled } from "@mui/joy/styles"
import Box from "@mui/joy/Box"
import Typography from "@mui/joy/Typography"
import IconButton from "@mui/joy/IconButton"
import useAlerts from "../../hooks/useAlerts"
import { usePortalApi } from "../../connections"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { snackAlert } from "../SnackAlerts"
import { currentUser, isHuxleyUser } from "../../state/auth.state"
import { handleApiError } from "../../utils/apiUtils"

const AlertBadge = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "4.5px",
  right: "2.5px",
  width: "17px",
  height: "17px",
  borderRadius: "50%",
  border: "1px solid white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.danger[500],
}))

const AlertsSidebarIcon = ({
  target,
  icon,
}: {
  target: string
  icon: ReactElement
}) => {
  const nav = useNavigate()
  const { unreadCount, listUnreadAlerts } = useAlerts()
  const api = usePortalApi()
  const user = useRecoilValue(currentUser)
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const isAdmin = useRecoilValue(isHuxleyUser)
  const location = useLocation()

  const fetchUnread = async () => {
    try {
      await listUnreadAlerts()
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  useEffect(() => {
    if (
      api === undefined ||
      !user ||
      isAdmin === undefined ||
      isAdmin === true ||
      location.pathname === "/"
    )
      return

    let intervalId: NodeJS.Timeout

    if (!isAdmin) {
      fetchUnread()
      intervalId = setInterval(() => {
        fetchUnread()
      }, 5 * 60 * 1000)
    }

    return () => clearInterval(intervalId)
  }, [api, location.pathname, user, isAdmin])

  return (
    <Box sx={{ position: "relative" }}>
      <IconButton
        size="lg"
        variant="plain"
        color="neutral"
        component="a"
        onClick={(_) => nav(target)}
      >
        {icon}
      </IconButton>

      {unreadCount > 0 && (
        <AlertBadge>
          <Typography
            sx={{
              fontSize: "8px",
              fontWeight: "bold",
              color: "white",
            }}
          >
            {unreadCount}
          </Typography>
        </AlertBadge>
      )}
    </Box>
  )
}

export default AlertsSidebarIcon
