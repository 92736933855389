/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SleepStudyOrderUpdate
 */
export interface SleepStudyOrderUpdate {
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderUpdate
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderUpdate
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderUpdate
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderUpdate
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderUpdate
     */
    zip?: string;
    /**
     * 
     * @type {Date}
     * @memberof SleepStudyOrderUpdate
     */
    scheduledDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderUpdate
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderUpdate
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderUpdate
     */
    oxygenDesatThreshold?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderUpdate
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderUpdate
     */
    orderingPhysicianId?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderUpdate
     */
    interpretingPhysicianId?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderUpdate
     */
    assignedDeviceSerialNumber?: string;
}

/**
 * Check if a given object implements the SleepStudyOrderUpdate interface.
 */
export function instanceOfSleepStudyOrderUpdate(value: object): value is SleepStudyOrderUpdate {
    return true;
}

export function SleepStudyOrderUpdateFromJSON(json: any): SleepStudyOrderUpdate {
    return SleepStudyOrderUpdateFromJSONTyped(json, false);
}

export function SleepStudyOrderUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SleepStudyOrderUpdate {
    if (json == null) {
        return json;
    }
    return {
        
        'address1': json['address_1'] == null ? undefined : json['address_1'],
        'address2': json['address_2'] == null ? undefined : json['address_2'],
        'city': json['city'] == null ? undefined : json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'zip': json['zip'] == null ? undefined : json['zip'],
        'scheduledDate': json['scheduled_date'] == null ? undefined : (new Date(json['scheduled_date'])),
        'status': json['status'] == null ? undefined : json['status'],
        'type': json['type'] == null ? undefined : json['type'],
        'oxygenDesatThreshold': json['oxygen_desat_threshold'] == null ? undefined : json['oxygen_desat_threshold'],
        'notes': json['notes'] == null ? undefined : json['notes'],
        'orderingPhysicianId': json['ordering_physician_id'] == null ? undefined : json['ordering_physician_id'],
        'interpretingPhysicianId': json['interpreting_physician_id'] == null ? undefined : json['interpreting_physician_id'],
        'assignedDeviceSerialNumber': json['assigned_device_serial_number'] == null ? undefined : json['assigned_device_serial_number'],
    };
}

export function SleepStudyOrderUpdateToJSON(json: any): SleepStudyOrderUpdate {
    return SleepStudyOrderUpdateToJSONTyped(json, false);
}

export function SleepStudyOrderUpdateToJSONTyped(value?: SleepStudyOrderUpdate | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'address_1': value['address1'],
        'address_2': value['address2'],
        'city': value['city'],
        'state': value['state'],
        'zip': value['zip'],
        'scheduled_date': value['scheduledDate'] == null ? undefined : ((value['scheduledDate']).toISOString().substring(0,10)),
        'status': value['status'],
        'type': value['type'],
        'oxygen_desat_threshold': value['oxygenDesatThreshold'],
        'notes': value['notes'],
        'ordering_physician_id': value['orderingPhysicianId'],
        'interpreting_physician_id': value['interpretingPhysicianId'],
        'assigned_device_serial_number': value['assignedDeviceSerialNumber'],
    };
}

