import { useState } from "react"
import { styled } from "@mui/joy/styles"
import { useSetRecoilState } from "recoil"
import Box from "@mui/joy/Box"
import CircularProgress from "@mui/joy/CircularProgress"
import Typography from "@mui/joy/Typography"
import Stack from "@mui/joy/Stack"
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import ReplayIcon from "@mui/icons-material/Replay"
import CloseIcon from "@mui/icons-material/Close"
import { IconButton } from "@mui/material"
import { useUploadQueue } from "../../hooks"
import { studyToBeUploaded } from "../../state/modal.state"

export const StyledBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: 82,
  right: 16,
  cursor: "pointer",
  borderRadius: 8,
  backgroundColor: "white",
  border: "0.3px solid",
  borderColor: theme.palette.neutral.outlinedBorder, // Correct theme access
  boxShadow: "lg",
  transition: "all 0.3s ease-in-out",
  zIndex: 2,
}))

const UploadsStatusBar = () => {
  const [isHovered, setIsHovered] = useState(false)
  const setStudyToBeUploaded = useSetRecoilState(studyToBeUploaded)

  const { queue, removeUploadTask } = useUploadQueue()

  const inProgressOrFailed = queue.filter(
    (task) => task.status === "uploading" || task.status === "failed"
  )
  const allFailed = queue.every((task) => task.status === "failed")

  const openUploadModal = (id: string, readableId: string) => {
    setStudyToBeUploaded({
      id,
      readableId,
    })
    removeUploadTask(id)
  }

  if (inProgressOrFailed.length === 0) return null

  return (
    <StyledBox
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          sx={{
            p: 1,
          }}
        >
          {inProgressOrFailed.map((item) => {
            const uploadFailed = item.status === "failed"
            const downloadFile = item.type === "DOWNLOAD_REPORT"
            const IconAction = downloadFile
              ? CloudDownloadIcon
              : InsertDriveFileOutlinedIcon
            return (
              <Stack
                direction="row"
                key={item.id}
                spacing={1}
                alignItems="center"
              >
                {uploadFailed ? (
                  <ErrorOutlineIcon sx={{ color: "#E47474" }} />
                ) : (
                  <IconAction color="disabled" />
                )}

                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{
                    width: downloadFile ? 160 : 120,
                  }}
                >
                  <Typography
                    level="body-sm"
                    noWrap
                    sx={{
                      color: "neutral.500",
                    }}
                  >
                    {!downloadFile && "Study: "}
                    {item.readableId}
                  </Typography>
                  {uploadFailed && !downloadFile && (
                    <IconButton
                      onClick={() => openUploadModal(item.id, item.readableId)}
                      sx={{ padding: 0 }}
                    >
                      <ReplayIcon color="disabled" />
                    </IconButton>
                  )}
                </Stack>

                {uploadFailed ? (
                  <IconButton
                    onClick={() => removeUploadTask(item.id)}
                    sx={{ padding: 0 }}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <CircularProgress thickness={3} size={"sm"} />
                )}
              </Stack>
            )
          })}
        </Stack>
      ) : (
        <Stack
          alignItems="center"
          sx={{
            p: 0.5,
          }}
        >
          {allFailed ? (
            <ErrorOutlineIcon sx={{ color: "#E47474", fontSize: 40 }} />
          ) : (
            <CircularProgress thickness={5} />
          )}
        </Stack>
      )}
    </StyledBox>
  )
}

export default UploadsStatusBar
