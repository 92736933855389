/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatientIn
 */
export interface PatientIn {
    /**
     * 
     * @type {string}
     * @memberof PatientIn
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientIn
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientIn
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientIn
     */
    mrn: string;
    /**
     * 
     * @type {string}
     * @memberof PatientIn
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientIn
     */
    primaryPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientIn
     */
    secondaryPhone?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatientIn
     */
    dob: Date;
    /**
     * 
     * @type {string}
     * @memberof PatientIn
     */
    race: string;
    /**
     * 
     * @type {string}
     * @memberof PatientIn
     */
    sex: string;
    /**
     * 
     * @type {string}
     * @memberof PatientIn
     */
    referringPhysicianFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientIn
     */
    referringPhysicianLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientIn
     */
    referringPhysicianPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientIn
     */
    height?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientIn
     */
    weight?: number;
    /**
     * 
     * @type {string}
     * @memberof PatientIn
     */
    organizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientIn
     */
    organizationLocationId?: string;
}

/**
 * Check if a given object implements the PatientIn interface.
 */
export function instanceOfPatientIn(value: object): value is PatientIn {
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('mrn' in value) || value['mrn'] === undefined) return false;
    if (!('dob' in value) || value['dob'] === undefined) return false;
    if (!('race' in value) || value['race'] === undefined) return false;
    if (!('sex' in value) || value['sex'] === undefined) return false;
    return true;
}

export function PatientInFromJSON(json: any): PatientIn {
    return PatientInFromJSONTyped(json, false);
}

export function PatientInFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientIn {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['first_name'],
        'middleName': json['middle_name'] == null ? undefined : json['middle_name'],
        'lastName': json['last_name'],
        'mrn': json['mrn'],
        'email': json['email'] == null ? undefined : json['email'],
        'primaryPhone': json['primary_phone'] == null ? undefined : json['primary_phone'],
        'secondaryPhone': json['secondary_phone'] == null ? undefined : json['secondary_phone'],
        'dob': (new Date(json['dob'])),
        'race': json['race'],
        'sex': json['sex'],
        'referringPhysicianFirstName': json['referring_physician_first_name'] == null ? undefined : json['referring_physician_first_name'],
        'referringPhysicianLastName': json['referring_physician_last_name'] == null ? undefined : json['referring_physician_last_name'],
        'referringPhysicianPhone': json['referring_physician_phone'] == null ? undefined : json['referring_physician_phone'],
        'height': json['height'] == null ? undefined : json['height'],
        'weight': json['weight'] == null ? undefined : json['weight'],
        'organizationId': json['organization_id'] == null ? undefined : json['organization_id'],
        'organizationLocationId': json['organization_location_id'] == null ? undefined : json['organization_location_id'],
    };
}

export function PatientInToJSON(json: any): PatientIn {
    return PatientInToJSONTyped(json, false);
}

export function PatientInToJSONTyped(value?: PatientIn | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'first_name': value['firstName'],
        'middle_name': value['middleName'],
        'last_name': value['lastName'],
        'mrn': value['mrn'],
        'email': value['email'],
        'primary_phone': value['primaryPhone'],
        'secondary_phone': value['secondaryPhone'],
        'dob': ((value['dob']).toISOString().substring(0,10)),
        'race': value['race'],
        'sex': value['sex'],
        'referring_physician_first_name': value['referringPhysicianFirstName'],
        'referring_physician_last_name': value['referringPhysicianLastName'],
        'referring_physician_phone': value['referringPhysicianPhone'],
        'height': value['height'],
        'weight': value['weight'],
        'organization_id': value['organizationId'],
        'organization_location_id': value['organizationLocationId'],
    };
}

