import { labelData } from "../interfaces/events.interface"
import { EventPlot, EventType } from "../types/event.type"

export const eventTypes = [
  "Obstructive Apnea",
  "Desat",
  "Mixed Apnea",
  "Obstructive Hypopnea",
  "Resp",
  "Mixed Hypopnea",
  "Desaturation",
  "Snoring",
  "SANSASleepStage",
  "Chest",
  "Beats",
  "Rhythms",
]

export const colorMap: {
  [key in EventType]: string
} = {
  Resp: "#ff0000",
  Desat: "#7A6FFF",
  "Obstructive Hypopnea": "#009400",
  "Obstructive Apnea": "#FFA500",
  "Mixed Apnea": "#4B0082",
  "Mixed Hypopnea": "#A020F0",
  Desaturation: "#FFC0CB",
  Snoring: "#FFC0CB",
  SANSASleepStage: "#FFC0CB",
  Chest: "#FFC0CB",
  Beats: "#FFA500",
  Rhythms: "#4B0082",
}

export const defaultPlotEventTypeMap: {
  [key in EventPlot]: EventType
} = {
  HR: "Resp",
  SpO2: "Desat",
  ECG: "Beats",
  Position: "Desaturation",
  Actigraphy: "Obstructive Apnea",
  Snoring: "Snoring",
  Resp: "Resp",
  SANSASleepStage: "SANSASleepStage",
  Chest: "Chest",
  Beats: "Beats",
  Rhythms: "Rhythms",
}

export const beatsLabel: labelData = {
  V: "VEB",
  S: "SVEB",
  N: "Normal",
}

export const beatsDefaultLabel: labelData = {
  VEB: "V",
  SVEB: "S",
  Normal: "N",
}

export const rhythmsLabel: labelData = {
  SINUS_BRADY: "S. Brady",
  SINUS_TACHY: "S. Tach",
  OTHER_SVT: "SVT",
  AFIB_OR_AFLUTTER: "AF",
  VTACH: "V. Tach",
  ATRIAL_BIGEM: "A. Bigem",
  ATRIAL_TRIGEM: "A. Trigem",
  ATRIAL_QUADRIGEM: "A. Quadrigem",
  VENTRICULAR_BIGEM: "V. Bigem",
  VENTRICULAR_TRIGEM: "V. Trigem",
  VENTRICULAR_QUADRIGEM: "V. Quadrigem",
  SINUS_PAUSE: "S. Pause",
  SECOND_OR_THIRD_AVB: "AVB",
  OTHER: "Other",
  NSR: "NSR",
  BAD_QUALITY: "Bad quality",
}

export const rhythmsDefaultLabel: labelData = {
  "S. Brady": "SINUS_BRADY",
  "S. Tach": "SINUS_TACHY",
  SVT: "OTHER_SVT",
  AF: "AFIB_OR_AFLUTTER",
  "V. Tach": "VTACH",
  "A. Bigem": "ATRIAL_BIGEM",
  "A. Trigem": "ATRIAL_TRIGEM",
  "A. Quadrigem": "ATRIAL_QUADRIGEM",
  "V. Bigem": "VENTRICULAR_BIGEM",
  "V. Trigem": "VENTRICULAR_TRIGEM",
  "V. Quadrigem": "VENTRICULAR_QUADRIGEM",
  "S. Pause": "SINUS_PAUSE",
  AVB: "SECOND_OR_THIRD_AVB",
  Other: "OTHER",
  NSR: "NSR",
  "Bad quality": "BAD_QUALITY",
}

export const selectEventTypes: labelData = {
  Resp: "Resp",
  Desat: "Desat",
}
