import CssBaseline from "@mui/joy/CssBaseline"
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles"
import SnackAlerts from "./components/SnackAlerts"
import "@aws-amplify/ui-react/styles.css"
import {
  THEME_ID as MATERIAL_THEME_ID,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
} from "@mui/material/styles"
import { Authenticator } from "@aws-amplify/ui-react"
import Router from "./router/router"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"

const materialTheme = materialExtendTheme()

function App() {
  return (
    <Authenticator.Provider>
      <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
        <JoyCssVarsProvider disableTransitionOnChange>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
            <Router />
            <SnackAlerts />
          </LocalizationProvider>
        </JoyCssVarsProvider>
      </MaterialCssVarsProvider>
    </Authenticator.Provider>
  )
}

export default App
