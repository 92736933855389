import { NotificationOut } from "../generated/fetchclient"
import { atom } from "recoil"

export const alerts = atom<NotificationOut[]>({
  key: "alerts",
  default: [],
})

export const unreadCountAtom = atom<number>({
  key: "unreadCountAtom",
  default: 0,
})
