import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil"
import { currentUser, isHuxleyUser, JWT } from "../state/auth.state"
import { useCookies } from "react-cookie"
import { useNavigate } from "react-router-dom"
import { usePortalApi } from "../connections"
import { useLDClient } from "launchdarkly-react-client-sdk"
import { UserUpdate } from "../generated/fetchclient/models/UserUpdate"
import { fetchAuthSession, signOut } from "@aws-amplify/auth"
import { snackAlert } from "../components/SnackAlerts"
import { handleApiError, isTokenExpired } from "../utils/apiUtils"
import { uploadsQueueState } from "../state/uploadFiles.state"
import { userSettingsAtom } from "../state/study.state"
import { UserSettings } from "../types/userSettings.type"

const useUserAuthenticator = () => {
  const setCurrentUser = useSetRecoilState(currentUser)
  const setIsHuxleyUser = useSetRecoilState(isHuxleyUser)
  const resetIsHuxleyUser = useResetRecoilState(isHuxleyUser)
  const resetCurrentUser = useResetRecoilState(currentUser)
  const resetJwt = useResetRecoilState(JWT)
  const resetLoadingQueueTasks = useResetRecoilState(uploadsQueueState)
  const [cookies, setCookie, removeCookie] = useCookies()
  const navigate = useNavigate()
  const api = usePortalApi()
  const ldClient = useLDClient()
  const [jwt, setJWT] = useRecoilState(JWT)
  const setSnackAlertMsg = useSetRecoilState(snackAlert)

  const setUserSettings = useSetRecoilState(userSettingsAtom)
  const userSettingsVal = useRecoilValue(userSettingsAtom)
  const resetUserSettings = useResetRecoilState(userSettingsAtom)

  const setJWTToken = async () => {
    const session = await fetchAuthSession({ forceRefresh: true })
    const token = session.tokens?.idToken?.toString()
    if (token) {
      setJWT(token)
    }
    return token
  }

  const getValidToken = async () => {
    if (jwt && !isTokenExpired(jwt)) {
      return jwt
    }

    try {
      const session = await fetchAuthSession()
      const newToken = session.tokens?.idToken?.toString()
      if (newToken) {
        setJWT(newToken)
        return newToken
      }
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
    return null
  }

  const userAuthentication = async () => {
    if (api === undefined) return

    try {
      const userData = await api.currentUser()
      setCurrentUser(userData)
      const userSettings = await api.getUserSettings()

      setUserSettings({
        ...userSettingsVal,
        ...(userSettings.settings as UserSettings),
      })
      if (
        userData.groups.includes("HuxleySupport") ||
        userData.groups.includes("HuxleyAdmin")
      ) {
        if (cookies.sessionid) {
          setIsHuxleyUser(true)
        } else {
          const sessionData = await api.logIn()
          const { sessionKey } = sessionData
          if (sessionKey) {
            setCookie("sessionid", sessionKey, {
              sameSite:
                process.env.REACT_APP_RUNTIME_ENV === "prod" ? "strict" : "lax",
              secure: true,
            })
            setIsHuxleyUser(true)
          } else {
            setIsHuxleyUser(false)
          }
        }
      } else {
        setIsHuxleyUser(false)
      }
      ldClient?.identify(
        {
          kind: "user",
          key: userData.uuid,
          name: userData.firstName + " " + userData.lastName,
        },
        undefined
      )
      return true
    } catch (error) {
      userSignOut()
    }
  }

  const updateCurrentUser = async (userData: UserUpdate) => {
    try {
      await api?.updateUserProfile({ userUpdate: userData })
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  const userSignOut = async () => {
    resetIsHuxleyUser()
    resetJwt()
    resetCurrentUser()
    resetUserSettings()
    removeCookie("sessionid")
    localStorage.clear()
    sessionStorage.clear()
    resetLoadingQueueTasks()
    await signOut()
    navigate("/login")
  }

  return {
    userSignOut,
    setJWTToken,
    getValidToken,
    userAuthentication,
    updateCurrentUser,
  }
}

export default useUserAuthenticator
