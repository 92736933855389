import {
  Authenticator,
  ThemeProvider as CognitoThemeProvider,
  Theme,
} from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"
import Box from "@mui/joy/Box"
import { useTheme } from "@mui/joy"
import { StyledLogo } from "../../styled/Img"

const components = {
  SignIn: {
    Header() {
      return (
        <Box
          sx={{
            pt: { xs: 1, sm: 3 },
          }}
        >
          <StyledLogo src="/img/SANSA_Logo_Color_byHuxley@2x.png" alt="logo" />
        </Box>
      )
    },
  },
}

const AuthStyle = () => {
  const theme = useTheme()
  const cognitoTheme: Theme = {
    name: "Auth Example Theme",
    tokens: {
      colors: {
        background: {
          primary: {
            value: theme.vars.palette.background.body,
          },
          secondary: {
            value: theme.vars.palette.background.body,
          },
        },
        font: {
          interactive: {
            value: theme.vars.palette.text.primary,
          },
        },
        brand: {
          primary: {
            "10": theme.vars.palette.primary[100],
            "80": theme.vars.palette.primary[500],
            "90": theme.vars.palette.primary[500],
            "100": theme.vars.palette.primary[700],
          },
        },
      },
      components: {
        authenticator: {
          router: {
            borderColor: {
              value: theme.vars.palette.divider,
            },
            borderWidth: {
              value: "0px",
            },
            borderStyle: {
              value: "solid",
            },
            boxShadow: {
              value: "none",
            },
          },
        },
        button: {
          primary: {
            backgroundColor: theme.vars.palette.primary[500],
            _hover: {
              backgroundColor: theme.vars.palette.primary[500],
            },
          },
          link: {
            color: "#000",
            _hover: {
              color: { value: "#000" }, // Hover color
            },
          },
        },
      },
    },
  }

  return (
    <CognitoThemeProvider theme={cognitoTheme}>
      <Authenticator hideSignUp={true} components={components} />
    </CognitoThemeProvider>
  )
}

export default AuthStyle
