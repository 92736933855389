/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PresignedUrlWithParamsResponse
 */
export interface PresignedUrlWithParamsResponse {
    /**
     * 
     * @type {string}
     * @memberof PresignedUrlWithParamsResponse
     */
    presignedUrl?: string;
    /**
     * 
     * @type {object}
     * @memberof PresignedUrlWithParamsResponse
     */
    fields?: object;
}

/**
 * Check if a given object implements the PresignedUrlWithParamsResponse interface.
 */
export function instanceOfPresignedUrlWithParamsResponse(value: object): value is PresignedUrlWithParamsResponse {
    return true;
}

export function PresignedUrlWithParamsResponseFromJSON(json: any): PresignedUrlWithParamsResponse {
    return PresignedUrlWithParamsResponseFromJSONTyped(json, false);
}

export function PresignedUrlWithParamsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PresignedUrlWithParamsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'presignedUrl': json['presigned_url'] == null ? undefined : json['presigned_url'],
        'fields': json['fields'] == null ? undefined : json['fields'],
    };
}

export function PresignedUrlWithParamsResponseToJSON(json: any): PresignedUrlWithParamsResponse {
    return PresignedUrlWithParamsResponseToJSONTyped(json, false);
}

export function PresignedUrlWithParamsResponseToJSONTyped(value?: PresignedUrlWithParamsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'presigned_url': value['presignedUrl'],
        'fields': value['fields'],
    };
}

