import { ResponseError } from "../generated/fetchclient/runtime"
import { SetterOrUpdater } from "recoil"
import { SnackAlert } from "../types/snackAlert.types"

export function handleApiError(fn: SetterOrUpdater<SnackAlert>) {
  return async (error: unknown | string, customExtraMsg?: string) => {
    let message = ""
    try {
      const response = await (error as ResponseError).response.json()
      if (typeof response.detail === "string") {
        message = response.detail
      } else {
        console.error("Error detail:", response.detail)
      }
      // eslint-disable-next-line prettier/prettier
    } catch (e) {}

    const errorMessage =
      error instanceof Error
        ? error.message
        : message || String(error) || "An API error occurred"
    fn({
      open: true,
      message: customExtraMsg
        ? `${errorMessage} ${customExtraMsg}`
        : errorMessage,
      severity: "error",
      autoHideDuration: 5000,
    })
  }
}

export const isTokenExpired = (token: string | null): boolean => {
  if (!token) return true
  try {
    const decoded = JSON.parse(atob(token.split(".")[1])) // Decode JWT payload
    return Date.now() >= decoded.exp * 1000 // Compare expiration time
  } catch (error) {
    return true // Assume expired if decoding fails
  }
}
