import { snackAlert } from "../components/SnackAlerts"
import { usePortalApi } from "../connections"
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil"
import { handleApiError } from "../utils/apiUtils"
import { alerts, unreadCountAtom } from "../state/alerts.state"

const useAlerts = () => {
  const api = usePortalApi()
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const setAlerts = useSetRecoilState(alerts)
  const setUnreadCount = useSetRecoilState(unreadCountAtom)
  const resetAlerts = useResetRecoilState(alerts)
  const resetUnreadCount = useResetRecoilState(unreadCountAtom)

  /**
   * Lists all alerts for the current user.
   */
  const listAlerts = async () => {
    if (api === undefined) return

    try {
      const alerts = await api.getNotifications()
      setAlerts(alerts.items)
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Lists paginated alerts for the current user.
   * @param limit - Limit of the query.
   * @param offset - Offset of the query
   */
  const listAlertsPaginated = async (limit: number, offset?: number) => {
    if (!api) return
    try {
      const response = await api.getNotifications({ offset, limit })
      if (offset && offset > 0) {
        setAlerts((prev) => [...prev, ...response.items])
      } else {
        setAlerts(response.items)
      }
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Lists unread alerts for the current user.
   */
  const listUnreadAlerts = async () => {
    if (api === undefined) return

    try {
      const { count } = await api.getUnreadNotifications()
      setUnreadCount(count)
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Marks a specific alert as read.
   *
   * @param alertId - The ID of the alert to mark as read.
   */
  const markAlertAsRead = async (alertId: string) => {
    if (api === undefined) return

    try {
      const markAlert = await api.markNotificationAsRead({
        notificationId: alertId,
      })
      await listUnreadAlerts()
      return markAlert
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Marks all alerts as read for the current user.
   */
  const markAllAlertAsRead = async () => {
    if (api === undefined) return

    try {
      const markAlerts = await api.markAllNotificationsAsRead()
      resetUnreadCount()
      return markAlerts
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Deletes a specific alert.
   *
   * @param alertId - The ID of the alert to delete.
   */
  const deleteAlert = async (alertId: string) => {
    if (api === undefined) return

    try {
      const alert = await api.deleteNotification({ notificationId: alertId })
      setSnackAlertMsg({
        open: true,
        message: "Alert Deleted",
        severity: "success",
        autoHideDuration: 5000,
      })
      await listUnreadAlerts()
      return alert
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Deletes all alerts for the current user.
   */
  const deleteAlerts = async () => {
    if (api === undefined) return

    try {
      const alert = await api.deleteAllNotifications()
      setSnackAlertMsg({
        open: true,
        message: "Alert Deleted",
        severity: "success",
        autoHideDuration: 5000,
      })
      resetUnreadCount()
      return alert
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  const resetAlertsData = () => {
    resetAlerts()
    resetUnreadCount()
  }

  return {
    alerts: useRecoilValue(alerts),
    unreadCount: useRecoilValue(unreadCountAtom),
    listAlerts,
    listUnreadAlerts,
    markAlertAsRead,
    markAllAlertAsRead,
    deleteAlert,
    deleteAlerts,
    listAlertsPaginated,
    resetAlertsData,
  }
}

export default useAlerts
