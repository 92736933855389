/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SleepStudyOrderOut } from './SleepStudyOrderOut';
import {
    SleepStudyOrderOutFromJSON,
    SleepStudyOrderOutFromJSONTyped,
    SleepStudyOrderOutToJSON,
    SleepStudyOrderOutToJSONTyped,
} from './SleepStudyOrderOut';

/**
 * 
 * @export
 * @interface PagedSleepStudyOrderOut
 */
export interface PagedSleepStudyOrderOut {
    /**
     * 
     * @type {Array<SleepStudyOrderOut>}
     * @memberof PagedSleepStudyOrderOut
     */
    items: Array<SleepStudyOrderOut>;
    /**
     * 
     * @type {number}
     * @memberof PagedSleepStudyOrderOut
     */
    count: number;
}

/**
 * Check if a given object implements the PagedSleepStudyOrderOut interface.
 */
export function instanceOfPagedSleepStudyOrderOut(value: object): value is PagedSleepStudyOrderOut {
    if (!('items' in value) || value['items'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    return true;
}

export function PagedSleepStudyOrderOutFromJSON(json: any): PagedSleepStudyOrderOut {
    return PagedSleepStudyOrderOutFromJSONTyped(json, false);
}

export function PagedSleepStudyOrderOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedSleepStudyOrderOut {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(SleepStudyOrderOutFromJSON)),
        'count': json['count'],
    };
}

export function PagedSleepStudyOrderOutToJSON(json: any): PagedSleepStudyOrderOut {
    return PagedSleepStudyOrderOutToJSONTyped(json, false);
}

export function PagedSleepStudyOrderOutToJSONTyped(value?: PagedSleepStudyOrderOut | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'items': ((value['items'] as Array<any>).map(SleepStudyOrderOutToJSON)),
        'count': value['count'],
    };
}

