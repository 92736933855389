/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SleepStudyOrderIn
 */
export interface SleepStudyOrderIn {
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderIn
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderIn
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderIn
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderIn
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderIn
     */
    zip: string;
    /**
     * 
     * @type {Date}
     * @memberof SleepStudyOrderIn
     */
    scheduledDate: Date;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderIn
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderIn
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderIn
     */
    oxygenDesatThreshold: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderIn
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderIn
     */
    patientId: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderIn
     */
    orderingPhysicianId: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderIn
     */
    interpretingPhysicianId: string;
}

/**
 * Check if a given object implements the SleepStudyOrderIn interface.
 */
export function instanceOfSleepStudyOrderIn(value: object): value is SleepStudyOrderIn {
    if (!('address1' in value) || value['address1'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('zip' in value) || value['zip'] === undefined) return false;
    if (!('scheduledDate' in value) || value['scheduledDate'] === undefined) return false;
    if (!('oxygenDesatThreshold' in value) || value['oxygenDesatThreshold'] === undefined) return false;
    if (!('patientId' in value) || value['patientId'] === undefined) return false;
    if (!('orderingPhysicianId' in value) || value['orderingPhysicianId'] === undefined) return false;
    if (!('interpretingPhysicianId' in value) || value['interpretingPhysicianId'] === undefined) return false;
    return true;
}

export function SleepStudyOrderInFromJSON(json: any): SleepStudyOrderIn {
    return SleepStudyOrderInFromJSONTyped(json, false);
}

export function SleepStudyOrderInFromJSONTyped(json: any, ignoreDiscriminator: boolean): SleepStudyOrderIn {
    if (json == null) {
        return json;
    }
    return {
        
        'address1': json['address_1'],
        'address2': json['address_2'] == null ? undefined : json['address_2'],
        'city': json['city'],
        'state': json['state'],
        'zip': json['zip'],
        'scheduledDate': (new Date(json['scheduled_date'])),
        'status': json['status'] == null ? undefined : json['status'],
        'type': json['type'] == null ? undefined : json['type'],
        'oxygenDesatThreshold': json['oxygen_desat_threshold'],
        'notes': json['notes'] == null ? undefined : json['notes'],
        'patientId': json['patient_id'],
        'orderingPhysicianId': json['ordering_physician_id'],
        'interpretingPhysicianId': json['interpreting_physician_id'],
    };
}

export function SleepStudyOrderInToJSON(json: any): SleepStudyOrderIn {
    return SleepStudyOrderInToJSONTyped(json, false);
}

export function SleepStudyOrderInToJSONTyped(value?: SleepStudyOrderIn | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'address_1': value['address1'],
        'address_2': value['address2'],
        'city': value['city'],
        'state': value['state'],
        'zip': value['zip'],
        'scheduled_date': ((value['scheduledDate']).toISOString().substring(0,10)),
        'status': value['status'],
        'type': value['type'],
        'oxygen_desat_threshold': value['oxygenDesatThreshold'],
        'notes': value['notes'],
        'patient_id': value['patientId'],
        'ordering_physician_id': value['orderingPhysicianId'],
        'interpreting_physician_id': value['interpretingPhysicianId'],
    };
}

