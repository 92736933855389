import { useRecoilState } from "recoil"
import { uploadsQueueState } from "../state/uploadFiles.state"
import { typeLoader, UploadTask } from "../interfaces"

const useUploadQueue = () => {
  const [queue, setQueue] = useRecoilState(uploadsQueueState)

  const addUploadTask = (
    id: string,
    readableId: string,
    fileName: string,
    type: typeLoader
  ) => {
    setQueue((prev) => {
      const filtered = prev.filter((task) => task.id !== id)
      return [
        ...filtered,
        { id, readableId, fileName, status: "uploading" as const, type },
      ]
    })
    return id
  }

  const updateUploadTask = (id: string, updates: Partial<UploadTask>) => {
    setQueue((prev) =>
      prev.map((task) => (task.id === id ? { ...task, ...updates } : task))
    )
  }

  const removeUploadTask = (id: string) => {
    setQueue((prev) => prev.filter((task) => task.id !== id))
  }

  return {
    queue,
    addUploadTask,
    removeUploadTask,
    updateUploadTask,
  }
}

export default useUploadQueue
