import { ReactElement } from "react"
import Typography from "@mui/joy/Typography"
import IconButton from "@mui/joy/IconButton"
import Stack from "@mui/joy/Stack"
import { useNavigate } from "react-router-dom"
import { Visibility } from "../../types/event.type"
import AlertsSidebarIcon from "./AlertsSidebarIcon"

type SidebarLinkParams = {
  target: string
  icon: ReactElement
  label: string
  visibility?: Visibility
}

export default function SidebarLink({
  target,
  icon,
  label,
  visibility = "visible",
}: SidebarLinkParams) {
  const nav = useNavigate()

  return (
    <Stack alignItems="center" spacing={0.2} visibility={visibility}>
      {label === "Alerts" ? (
        <AlertsSidebarIcon target={target} icon={icon} />
      ) : (
        <IconButton
          size="lg"
          variant="plain"
          color="neutral"
          component="a"
          onClick={(_) => nav(target)}
        >
          {icon}
        </IconButton>
      )}

      <Typography level="body-xs">{label}</Typography>
    </Stack>
  )
}
