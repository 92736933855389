import { useAuthenticator } from "@aws-amplify/ui-react"
import { Configuration, DefaultApi } from "./generated/fetchclient"
import { useRecoilState, useSetRecoilState } from "recoil"
import { useMemo } from "react"
import { JWT } from "./state/auth.state"
import { djangoAPIHost } from "./const/djangoApi.const"
import { fetchAuthSession } from "@aws-amplify/auth"
import { handleApiError, isTokenExpired } from "./utils/apiUtils"
import { snackAlert } from "./components/SnackAlerts"

/**
 * usePortalApi is a hook that constructs a PortalApi instance used for backend requests.
 */

export const usePortalApi = (): DefaultApi | undefined => {
  const { user } = useAuthenticator()
  const [jwt, setJWT] = useRecoilState(JWT)
  const setSnackAlertMsg = useSetRecoilState(snackAlert)

  // Function to fetch a new token, avoiding duplicate requests
  const getValidToken = async (): Promise<string | null> => {
    if (jwt && !isTokenExpired(jwt)) {
      return jwt
    }

    try {
      const session = await fetchAuthSession()
      const newToken = session.tokens?.idToken?.toString()
      if (newToken) {
        setJWT(newToken)
        return newToken
      }
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
    return null
  }

  const api = useMemo(() => {
    if (!user || !jwt) return

    const configuration = new Configuration({
      middleware: [
        {
          pre: async (context) => {
            const validToken = await getValidToken()
            if (validToken) {
              context.init.headers = {
                ...context.init.headers,
                Authorization: `Bearer ${validToken}`,
              }
            } else {
              handleApiError(setSnackAlertMsg)("Unauthorized")
            }
            return context
          },
        },
      ],
      basePath: djangoAPIHost,
    })
    return new DefaultApi(configuration)
  }, [user, jwt])
  return api
}
