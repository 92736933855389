/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationOut
 */
export interface NotificationOut {
    /**
     * 
     * @type {string}
     * @memberof NotificationOut
     */
    uuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationOut
     */
    isRead?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationOut
     */
    message?: string;
    /**
     * 
     * @type {object}
     * @memberof NotificationOut
     */
    metadata?: object;
    /**
     * 
     * @type {string}
     * @memberof NotificationOut
     */
    type: string;
    /**
     * 
     * @type {Date}
     * @memberof NotificationOut
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the NotificationOut interface.
 */
export function instanceOfNotificationOut(value: object): value is NotificationOut {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    return true;
}

export function NotificationOutFromJSON(json: any): NotificationOut {
    return NotificationOutFromJSONTyped(json, false);
}

export function NotificationOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationOut {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'isRead': json['is_read'] == null ? undefined : json['is_read'],
        'message': json['message'] == null ? undefined : json['message'],
        'metadata': json['metadata'] == null ? undefined : json['metadata'],
        'type': json['type'],
        'createdAt': (new Date(json['created_at'])),
    };
}

export function NotificationOutToJSON(json: any): NotificationOut {
    return NotificationOutToJSONTyped(json, false);
}

export function NotificationOutToJSONTyped(value?: NotificationOut | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'uuid': value['uuid'],
        'is_read': value['isRead'],
        'message': value['message'],
        'metadata': value['metadata'],
        'type': value['type'],
        'created_at': ((value['createdAt']).toISOString()),
    };
}

