import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { isHuxleyUser, currentUser } from "../state/auth.state"
import { CognitoLogin } from "../views/auth/CognitoLogin"
import AuthenticatedLayout from "../views/layout/authenticated"
import NotFoundView from "../views/NotFound"
import RequireAuth from "./RequireAuth"
import { lazy, Suspense } from "react"
import LoadingIndicator from "../components/loading/LoadingIndicator"

export function RequireHuxleyUser() {
  const isAdmin = useRecoilValue(isHuxleyUser)

  if (isAdmin === undefined) {
    // Avoid a flash of the not found view while authenticating
    return <></>
  } else if (isAdmin) {
    return <Outlet />
  } else {
    return <NotFoundView />
  }
}

export function RequireHuxleyAdminOrOrgAdmin() {
  const user = useRecoilValue(currentUser)

  if (user === undefined) {
    // Avoid a flash of the not found view while authenticating
    return <></>
  } else if (
    user.primaryRole.includes("Huxley") ||
    user.primaryRole === "OrgAdmin"
  ) {
    return <Outlet />
  } else {
    return <NotFoundView />
  }
}

export function DashboardLayout() {
  const isAdmin = useRecoilValue(isHuxleyUser)

  if (isAdmin === undefined) {
    // Avoid a flash of the not found view while authenticating
    return <></>
  } else if (isAdmin) {
    return <Navigate to="/internal" replace />
  } else {
    return <Navigate to="/studies" replace />
  }
}

export const makeRoute = ({
  path,
  element: Element,
}: {
  path: string
  element: React.LazyExoticComponent<React.ComponentType<unknown>>
}) => {
  return (
    <Route
      path={path}
      key={path}
      element={
        <Suspense fallback={<LoadingIndicator header="" content=" " />}>
          <Element />
        </Suspense>
      }
    />
  )
}

const Router = () => {
  const requireHuxleyUserRoutes = [
    {
      path: "/admin",
      element: lazy(() => import("../views/admin")),
    },
    {
      path: "/internal",
      element: lazy(
        () => import("../views/internal/studies/AdminStudiesContent")
      ),
    },
    {
      path: "/devices",
      element: lazy(() => import("../views/devices")),
    },
    {
      path: "/organizations",
      element: lazy(() => import("../views/organization")),
    },
  ]
  const authenticatedLayoutGeneralRoutes = [
    {
      path: "/profile",
      element: lazy(() => import("../views/profile/ProfileView")),
    },
    {
      path: "/studies",
      element: lazy(() => import("../views/studies")),
    },
    {
      path: "/dataset/:studyID",
      element: lazy(() => import("../views/scoring/StudyScoringContent")),
    },
    {
      path: "/alerts",
      element: lazy(() => import("../views/alerts")),
    },
    {
      path: "/patients/:patientID?",
      element: lazy(() => import("../views/patients")),
    },
  ]
  const requireHuxleyAdminOrOrgAdminRoutes = [
    {
      path: "/users",
      element: lazy(() => import("../views/users")),
    },
    {
      path: "/settings",
      element: lazy(() => import("../views/settings")),
    },
  ]
  return (
    <Routes>
      <Route path="/login" element={<CognitoLogin />} />
      <Route>
        <Route element={<RequireAuth />}>
          <Route element={<AuthenticatedLayout />}>
            <Route
              path="/"
              element={
                <Suspense fallback={<LoadingIndicator header="" content=" " />}>
                  <DashboardLayout />
                </Suspense>
              }
            />
            {authenticatedLayoutGeneralRoutes.map(({ path, element }) =>
              makeRoute({ path, element })
            )}
            <Route element={<RequireHuxleyUser />}>
              {requireHuxleyUserRoutes.map(({ path, element }) =>
                makeRoute({ path, element })
              )}
            </Route>
            <Route element={<RequireHuxleyAdminOrOrgAdmin />}>
              {requireHuxleyAdminOrOrgAdminRoutes.map(({ path, element }) =>
                makeRoute({ path, element })
              )}
            </Route>
          </Route>
        </Route>
      </Route>
      {/* Route here for 404*/}
      <Route
        path="*"
        element={
          <Suspense fallback={<LoadingIndicator header="" content=" " />}>
            <NotFoundView />
          </Suspense>
        }
      />
    </Routes>
  )
}

export default Router
