/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatientUpdate
 */
export interface PatientUpdate {
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    mrn?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    primaryPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    secondaryPhone?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatientUpdate
     */
    dob?: Date;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    race?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    sex?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    referringPhysicianFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    referringPhysicianLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    referringPhysicianPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    height?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientUpdate
     */
    weight?: number;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    organizationLocationId?: string;
}

/**
 * Check if a given object implements the PatientUpdate interface.
 */
export function instanceOfPatientUpdate(value: object): value is PatientUpdate {
    return true;
}

export function PatientUpdateFromJSON(json: any): PatientUpdate {
    return PatientUpdateFromJSONTyped(json, false);
}

export function PatientUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientUpdate {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['first_name'] == null ? undefined : json['first_name'],
        'middleName': json['middle_name'] == null ? undefined : json['middle_name'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
        'mrn': json['mrn'] == null ? undefined : json['mrn'],
        'email': json['email'] == null ? undefined : json['email'],
        'primaryPhone': json['primary_phone'] == null ? undefined : json['primary_phone'],
        'secondaryPhone': json['secondary_phone'] == null ? undefined : json['secondary_phone'],
        'dob': json['dob'] == null ? undefined : (new Date(json['dob'])),
        'race': json['race'] == null ? undefined : json['race'],
        'sex': json['sex'] == null ? undefined : json['sex'],
        'referringPhysicianFirstName': json['referring_physician_first_name'] == null ? undefined : json['referring_physician_first_name'],
        'referringPhysicianLastName': json['referring_physician_last_name'] == null ? undefined : json['referring_physician_last_name'],
        'referringPhysicianPhone': json['referring_physician_phone'] == null ? undefined : json['referring_physician_phone'],
        'height': json['height'] == null ? undefined : json['height'],
        'weight': json['weight'] == null ? undefined : json['weight'],
        'organizationLocationId': json['organization_location_id'] == null ? undefined : json['organization_location_id'],
    };
}

export function PatientUpdateToJSON(json: any): PatientUpdate {
    return PatientUpdateToJSONTyped(json, false);
}

export function PatientUpdateToJSONTyped(value?: PatientUpdate | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'first_name': value['firstName'],
        'middle_name': value['middleName'],
        'last_name': value['lastName'],
        'mrn': value['mrn'],
        'email': value['email'],
        'primary_phone': value['primaryPhone'],
        'secondary_phone': value['secondaryPhone'],
        'dob': value['dob'] == null ? undefined : ((value['dob']).toISOString().substring(0,10)),
        'race': value['race'],
        'sex': value['sex'],
        'referring_physician_first_name': value['referringPhysicianFirstName'],
        'referring_physician_last_name': value['referringPhysicianLastName'],
        'referring_physician_phone': value['referringPhysicianPhone'],
        'height': value['height'],
        'weight': value['weight'],
        'organization_location_id': value['organizationLocationId'],
    };
}

