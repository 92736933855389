/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeviceIn
 */
export interface DeviceIn {
    /**
     * 
     * @type {string}
     * @memberof DeviceIn
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceIn
     */
    deviceType?: string;
}

/**
 * Check if a given object implements the DeviceIn interface.
 */
export function instanceOfDeviceIn(value: object): value is DeviceIn {
    if (!('serialNumber' in value) || value['serialNumber'] === undefined) return false;
    return true;
}

export function DeviceInFromJSON(json: any): DeviceIn {
    return DeviceInFromJSONTyped(json, false);
}

export function DeviceInFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceIn {
    if (json == null) {
        return json;
    }
    return {
        
        'serialNumber': json['serial_number'],
        'deviceType': json['device_type'] == null ? undefined : json['device_type'],
    };
}

export function DeviceInToJSON(json: any): DeviceIn {
    return DeviceInToJSONTyped(json, false);
}

export function DeviceInToJSONTyped(value?: DeviceIn | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'serial_number': value['serialNumber'],
        'device_type': value['deviceType'],
    };
}

