/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SleepStudyInterpretationIn
 */
export interface SleepStudyInterpretationIn {
    /**
     * 
     * @type {object}
     * @memberof SleepStudyInterpretationIn
     */
    interpretationNotes: object;
    /**
     * 
     * @type {boolean}
     * @memberof SleepStudyInterpretationIn
     */
    locked: boolean;
}

/**
 * Check if a given object implements the SleepStudyInterpretationIn interface.
 */
export function instanceOfSleepStudyInterpretationIn(value: object): value is SleepStudyInterpretationIn {
    if (!('interpretationNotes' in value) || value['interpretationNotes'] === undefined) return false;
    if (!('locked' in value) || value['locked'] === undefined) return false;
    return true;
}

export function SleepStudyInterpretationInFromJSON(json: any): SleepStudyInterpretationIn {
    return SleepStudyInterpretationInFromJSONTyped(json, false);
}

export function SleepStudyInterpretationInFromJSONTyped(json: any, ignoreDiscriminator: boolean): SleepStudyInterpretationIn {
    if (json == null) {
        return json;
    }
    return {
        
        'interpretationNotes': json['interpretation_notes'],
        'locked': json['locked'],
    };
}

export function SleepStudyInterpretationInToJSON(json: any): SleepStudyInterpretationIn {
    return SleepStudyInterpretationInToJSONTyped(json, false);
}

export function SleepStudyInterpretationInToJSONTyped(value?: SleepStudyInterpretationIn | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'interpretation_notes': value['interpretationNotes'],
        'locked': value['locked'],
    };
}

